<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">观察记录</h3>
    <div class="detail">
      <div class="flex  backReturn">
        <h4 class="title">观察记录</h4>
        <el-button size="small" plain class="return" @click="$emit('changePage', 1,null,3)">返回列表页</el-button>
      </div>
      <el-form ref="observe" size="small" :model="observe" class="addForm" label-suffix=":"
               label-width="90px">
        <el-form-item label="观察日期" prop="handleTime" :rules="[{ required: true, message: '观察日期不能为空'}]">
          <el-date-picker class="smallWidth"
                          v-model="observe.handleTime"
                          type="date"
                          placeholder="请输入观察日期">
          </el-date-picker>
        </el-form-item>
        <!--        新增-->
        <el-tabs v-model="nowNumber" class="tabsCage">
          <el-tab-pane
              :key="key"
              v-for="(item, key) in tabs"
              :name="key"
          >
            <span slot="label">{{ key }}</span>
            <p class="remarkTit">备注：-正常，①死亡，②精神萎靡，③活动减少，④肿瘤红肿，⑤肿瘤溃破，⑥结痂，⑦其他 ，NA 已死亡</p>
            <el-table
                :data="item" border
                class="tableMember"
                :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
                style="width: calc(100% - 12px);font-size:14px;color:#333">
              <el-table-column label="笼号" prop="cageNo" show-overflow-tooltip></el-table-column>
              <el-table-column label="动物编号" prop="aniNo" show-overflow-tooltip></el-table-column>
              <el-table-column label="耳号" prop="overbit" show-overflow-tooltip></el-table-column>
              <el-table-column label="性别">
                <template slot-scope="scope">{{ scope.row.gender == 1 ? '♂' : '♀' }}</template>
              </el-table-column>
              <el-table-column label="临床症状" class-name="isClass" width="250">
                <template slot-scope="scope">
                  <template v-if="scope.row.symptom!=8">
                    <el-select v-model="scope.row.symptom" size="small" @change="((val)=>{change(scope.row,val)})"
                               style="width: 100%"
                               placeholder="请选择临床症状">
                      <el-option
                          v-for="(item,index) in options"
                          :key="index"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </template>
                  <span v-else style="color: #C0C4CC;font-size: 14px">NA 已死亡</span>
                </template>
              </el-table-column>
              <el-table-column label="备注" class-name="isClass" width="250">
                <template slot-scope="scope">
                  <el-input size="small" placeholder="请输入备注" v-model="scope.row.remark"
                            :disabled="scope.row.disabled"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" v-if="state==0">
                <template slot-scope="scope">
                  <div class="editColor">
                    <el-button type="text" @click="save(scope.row,scope.$index)">保存</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <template v-if="observe.endDetailList.length>0">
          <h4 class="title">动物尸体处理</h4>
          <el-table :data="observe.endDetailList" border
                    class="tableMember"
                    :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
                    style="width: calc(100% - 12px);font-size:14px;color:#333">
            <el-table-column label="组别" prop="groupName" show-overflow-tooltip></el-table-column>
            <el-table-column label="动物编号" prop="aniNo" show-overflow-tooltip></el-table-column>
            <el-table-column label="耳号" prop="overbit" show-overflow-tooltip></el-table-column>
            <el-table-column label="性别">
              <template slot-scope="scope">{{ scope.row.gender == 1 ? '♂' : '♀' }}</template>
            </el-table-column>
            <el-table-column label="处理方式" width="260">
              <template slot-scope="scope">
                <!--  0:  直接丢弃   1：解剖处理-->
                <template v-if="scope.row.show">
                  <el-radio-group v-model="scope.row.handle" class="radioGroup">
                    <el-radio :label="0">回收箱丢弃</el-radio>
                    <el-radio :label="1">解剖处理</el-radio>
                  </el-radio-group>
                </template>
                <span v-else>{{ scope.row.handle == 0 ? '回收箱丢弃' : '解剖处理' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="大体解剖观察" min-width="150" show-overflow-tooltip>
              <template slot-scope="scope">
                <template v-if="scope.row.handle == 1">
                  <template v-if="scope.row.show">
                    <el-input size="small" placeholder="请输入大体解剖观察" v-model="scope.row.dissection"></el-input>
                  </template>
                  <span v-else>{{ scope.row.dissection }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="备注" min-width="150" show-overflow-tooltip>
              <template slot-scope="scope">
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="请输入备注" v-model="scope.row.remark"></el-input>
                </template>
                <span v-else>{{ scope.row.remark }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="scope">
                <div class="editColor">
                  <template v-if="state==null||state==0">
                    <el-button type="text" @click="edit(scope.row,scope.$index)">{{ scope.row.show ? '保存' : '编辑' }}
                    </el-button>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <h3 class="tit">指定复核人</h3>
        <el-form-item label="复核人员">
          <el-radio-group v-model="observe.reviewName" class="radioGroup">
            <el-radio v-for="item in userList" :label="item.name" :key="item.userId"
                      @click.native="getAppId(item.userId,item.name)"
            > {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="fromSave">
          <el-button type="primary" size="small" @click="submitForm('observe',1)">记录完成</el-button>
          <el-button type="primary" plain size="small" @click="submitForm('observe',0)">保存</el-button>
          <template v-if="observeId">
            <el-button type="primary" plain size="small" @click="$emit('changePage', 3,observeId,3)">导出预览</el-button>
          </template>
        </div>
      </el-form>
    </div>
    <!--    动物尸体处理-->
    <el-dialog :close-on-click-modal="false"
               title="动物尸体处理"
               class="flex dialogForm"
               top="0"
               :visible.sync="dialogVisible"
               width="450px">
      <el-form ref="addForm" size="small" :model="dealWith" class="addForm" label-suffix=":"
               label-width="90px">
        <el-form-item label="处理方式">
          <el-radio-group v-model="dealWith.handle" class="radioGroup">
            <el-radio :label="0">回收箱丢弃</el-radio>
            <el-radio :label="1">解剖处理</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="解剖观察" v-if="dealWith.handle==1">
          <el-input v-model="dealWith.dissection" placeholder="请输入解剖观察结果" type="textarea" rows="3"></el-input>
        </el-form-item>
        <el-form-item label="备注信息">
          <el-input v-model="dealWith.remark" placeholder="请输入备注信息" type="textarea" rows="3"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="submitDeal">确认</el-button>
          <el-button size="small" plain type="primary" @click="dialogVisible=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "observe",
  props: ['observeId', 'id'],
  inject: ['reload'],
  data() {
    return {
      dialogVisible: false,
      options: [
        {
          value: 0,
          label: '_ 正常'
        }, {
          value: 1,
          label: '① 死亡'
        }, {
          value: 2,
          label: '② 精神萎靡'
        }, {
          value: 3,
          label: '③ 活动减少'
        }, {
          value: 4,
          label: '④ 肿瘤红肿 ，'
        }, {
          value: 5,
          label: '⑤ 肿瘤溃破'
        }, {
          value: 6,
          label: '⑥ 结痂'
        }, {
          value: 7,
          label: '⑦ 其他'
        }],
      observe: {
        handleTime: '',
        reviewer: null,
        reviewName: '',
        endDetailList: []
      },
      state: null,
      // 新增
      tabs: {},
      userList: [],
      nowNumber: 0,
      dealWith: {
        handle: 0,
        remark: '',
        dissection: ''
      }
    }
  },
  mounted() {
    this.$get('/subject/member/list/' + this.id).then(res => {
      if (res.status == 200) {
        this.userList = res.data
      }
    })
    // 获取详情
    if (this.observeId) {
      this.getObserDetail()
    } else {
      this.getGroup()
    }
  },
  methods: {
    // 指定复核人员
    getAppId(id, name) {
      this.observe.reviewer = id
      this.observe.reviewName = name
    },
    save(row) {
      let vm = this
      let params = {
        id: row.id,
        groupAniId: row.groupAniId,
        symptom: row.symptom,
        remark: row.remark,
        observeId: row.observeId,
      }
      if (row.symptom == 1) {
        params.endDetail = {
          subId: Number(vm.id),
          groupAniId: row.groupAniId,
          observeId: row.observeId,
          handle: this.dealWith.handle,
          dissection: this.dealWith.dissection,
          remark: this.dealWith.remark
        }
      }
      this.$putJson('/subject/obser/detail', params).then(res => {
        this.$message.success("修改成功")
        this.$nextTick(_=>{
          this.getObserDetail()
        })
      })
    },
    // 新增--获取实验组信息
    getGroup() {
      this.$get('/subject/observe/addPage/' + this.id).then(res => {
        if (res.status == 200) {
          this.tabs = res.data
          let arr = []
          if (this.nowNumber == 0) {
            for (const key in res.data) {
              arr.push(key)
              // 新增获取前一次数据，并且显示动物尸体处理方法
              res.data[key].forEach(item => {
                if (item.symptom == 1) {
                  /*this.options.push({
                    value: 8,
                    label: 'NA 已死亡'
                  })*/
                  item.symptom = 8
                  item.disabled = true
                }
              })
            }
            this.nowNumber = arr[0]
          }
        }
      })
    },

    getObserDetail() {
      this.$get('/subject/observe/' + this.observeId).then(res => {
        if (res.status == 200) {
          this.state = res.data.observe.state
          this.observe = {
            handleTime: res.data.observe.handleTime,
            reviewer: res.data.observe.reviewer,
            reviewName: res.data.observe.reviewName,
            endDetailList: res.data.endDetailList
          }
          this.tabs = res.data.groupMap
          let arr = []
          if (this.nowNumber == 0) {
            for (const key in res.data.groupMap) {
              arr.push(key)
            }
            this.nowNumber = arr[0]
          }
        }
      })
    },
    // 临床症状选择
    change(row, val) {
      if (val == 1) {
        this.dialogVisible = true
        this.dealWith = {
          handle: 0,
          remark: '',
          dissection: '',
          aniNo: row.aniNo,
          cageNo: row.cageNo,
          gender: row.gender,
          groupAniId: row.groupAniId,
          groupName: row.groupName,
          overbit: row.overbit,
          observeId: row.observeId,
          subId: Number(this.id),
          show: false
        }
      } else {
        // 当再次选择非死亡症状的时候 删除
        let arr = this.observe.endDetailList
        if (arr.length > 0) {
          for (let i = 0; i < arr.length; i++) {
            if (row.groupAniId == arr[i].groupAniId) {
              arr.splice(i, 1)
            }
          }
        }
      }
    },
    // 提交死亡小鼠
    submitDeal() {
      this.observe.endDetailList.push(this.dealWith)
      this.dialogVisible = false
    },
    edit(row, index) {
      row.show = !row.show
      this.$set(this.observe.endDetailList, index, row)
    },
    // 提交数据
    submitForm(formName, state) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if ($.trim(this.observe.reviewer).length === 0) {
            this.$message.warning("请选择复核人")
            return false;
          }
          this.observe.state = state
          this.observe.subId = Number(this.id)
          let url;
          if (this.state == null) {
            let lists = [], message = [], message2 = [], message3 = [];
            let result = true
            for (const key in this.tabs) {
              this.tabs[key].forEach(item => {
                if (item.symptom == null) {
                  result = result && false
                  message.push('临床症状不能为空')
                }
                if (item.symptom == 1 && this.observe.endDetailList.length == 0) {
                  result = result && false
                  message3.push('请填写临床症状为”死亡“的动物尸体处理动物尸体处理方式')
                }
                lists.push(item)
              })
            }
            this.observe.obserDetailList = lists
            if (this.observe.endDetailList.length > 0) {
              // 筛选动物尸体处理是否保存
              this.observe.endDetailList.forEach(item => {
                if (item.show) {
                  result = result && false
                  message2.push('请保存填写的动物尸体处理方式')
                }
              })
            }
            if (!result) {
              if (message && message.length > 0) {
                return this.$message({message: message[0], type: 'warning'})
              }
              if (message2 && message2.length > 0) {
                return this.$message({message: message2[0], type: 'warning'})
              }
              if (message3 && message3.length > 0) {
                return this.$message({message: message3[0], type: 'warning'})
              }
            }
          }
          if (this.state == null) {
            url = this.$postJson('/subject/observe', this.observe)
          } else {
            this.observe.id = this.observeId
            url = this.$putJson('/subject/observe', this.observe)
            // console.log(this.observe)
          }
          url.then(res => {
            if (res.status == 201 || res.status == 200) {
              this.$message.success('提交成功')
              this.$emit('changePage', 1, null, 3)
            }
          })
        } else {
          return false;
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
